import Config from "../../config";
import {Link} from "react-router-dom";

export default function PrivacyPolicy(){
    document.title = 'Privacy Policy';
    return(
        <main id="static-page">
            <div className='container'>
                <h1 className="page-title">Privacy Policy</h1>
                <section className="section page-section">
                    <div className='mb-5'>
                        <h4 className='text-primary'>WHEN DOES THIS POLICY APPLY?</h4>
                        <p>We have made this policy as short and easy to understand as possible, so please take your time to read it so you can have all the information to decide whether you want to sign up and share your data with us.</p>
                        <p>This policy applies to us, its subdomains and other web sites and services where this Privacy Policy is referred or made accessible for you to read and accept.</p>
                        <p>The personal data to which we have access is the data that you will voluntarily provide upon sign up as required for the use of certain services.</p>
                        <p>You can easily sign up via the available social media sign up options or by filling out the necessary online forms where this option is available. Fields marked with an asterisk (*) are mandatory. Lack to provide the mandatory data will preclude you from signing up.</p>
                        <p>This policy applies only when you voluntary enter personal data. Please refer to our <Link to='/cookies-policy'>Cookies Policy</Link> to know how we and third parties process data obtained by means of cookies and similar technologies.</p>
                    </div>

                    <div className='mb-5'>
                        <h4 className='text-primary'>WHAT ARE THE PURPOSES OF DATA PROCESSING AND THE LEGITIMATE BASIS?</h4>
                        <p><strong>Based on your consent:</strong></p>
                        <ul>
                            <li>If you subscribe, creation of a community of users interested in game, so you can share your opinion and preferred programs and solutions with other users and they can share it with you.</li>
                            <li>If you subscribed to our newsletter, to inform you about reviews, news, tutorials, opinions, all related to the world of game.</li>
                            <li>To study and analyze the information provided, as well as based on information obtained in an aggregate format for statistical and analytics purposes via cookies (see our <Link to='/cookies-policy'>Cookies Policy</Link> for more info), in order to evaluate new trends, develop new services or check the result of an ad campaign.</li>
                            <li>To show you relevant ads on the website, newsletters and web notifications.</li>
                            <li>To show you relevant ads in other websites and social networks that may be of your interest (see our <Link to='/cookies-policy'>Cookies Policy</Link> at this regards).</li>
                            <li>To check you are above 14 years old, so you can give us a valid consent to provide your data.</li>
                            <li>To answer your questions, complaints and provide general support, if you contact us via our support portal, other online forms or directly via email.</li>
                            <li>Provided you have voluntarily opted-in to receive our newsletter, you will receive customized ads on our newsletters.</li>
                        </ul>
                        <p>For children below 14 years old, we require the authorization of parents or guardians for signing up. Please contact us via email to privacy@{Config.domain} if you want your children to subscribe and we’ll provide you further instructions to do so.</p>
                        <p><strong>Based on our legitimate interest:</strong></p>
                        <ul>
                            <li>If you subscribed to our newsletter, to recommend you game, based on your interests, download history and navigation habits on the website.</li>
                            <li>To address you properly on our emails (i.e dear “John Smith”).</li>
                            <li>To create a user profile. We will not use your profile for automatic individual decision-making.</li>
                        </ul>
                        <p><strong>Based on our contractual relationship with you:</strong></p>
                        <ul>
                            <li>If you subscribed to our newsletter, to send you emails about updates, service announcements or as required to provide you with the subscribed service. This kind of communications do not have a commercial nature and we will inform you of how to unsubscribe on each communication.</li>
                            <li>To answer your questions, complaints and provide general support, if you contact us via our support portal, other online forms or directly via email.</li>
                        </ul>
                        <p><strong>Based on our legal obligations:</strong></p>
                        <ul>
                            <li>To check you are above 14 years old, so you can give us a valid consent to provide your data.</li>
                        </ul>
                        <p>We’ll keep your data as long as we don’t receive a deletion request from you and as long as permitted by Law.</p>
                    </div>


                    <div className='mb-5'>
                        <h4 className='text-primary'>HOW LONG WILL MY DATA BEING PROCESSED?</h4>
                        <p>We will keep your data until we receive a request for deletion from you or until you withdraw your consent and for as long as we need to retain it in order to comply with our legal obligations and exercise our rights of defence.</p>
                        <p>You can delete your Disqus account here and unsubscribe from our newsletter at any time via the link provided on the email. To unsubscribe from web push notification please follow these steps (section How to Disable Web Notifications).</p>
                    </div>


                    <div className='mb-5'>
                        <h4 className='text-primary'>WILL MY DATA BE SHARED WITH THIRD PARTIES?</h4>
                        <p><strong>Third-party service providers.</strong> We have engaged with several trusted third parties-service providers (data processors) in order to provide the service. For instance, they provide us with services such as hosting, data management or direct marketing related services, so they need to access certain data on as needed strictly basis. We require all its providers to certify that they have adopted adequate organizational and security measures to comply with our data protection standards and require them to commit to adopting further legal safeguards. Where the relevant provider is located outside the Economic European Area (EEA), we require the signature of EU standard clauses.</p>
                        <p><strong>Legal reasons:</strong> We may disclose personal information when we believe release is appropriate to comply with the law; enforce or apply our terms of use and other agreements; or protect our rights.</p>
                        <p><strong>Business Transfers:</strong> As we continue to develop our business, we might sell or buy other companies, subsidiaries, or business units. In such transactions, user information generally is one of the transferred business assets but remains subject to the promises made in any pre-existing privacy policies (unless, of course, you consent otherwise). Also, in the event that we or substantially all of its assets are acquired, user information will, of course, be one of the transferred assets.</p>
                        <p>Please visit our <Link to='/cookies-policy'>Cookies Policy</Link> to know the data we and third parties process via the usage of cookies and other similar technologies.</p>
                        <p>You can ask us for an updated list of third parties that process your data by sending a request to the email indicated in the next section.</p>
                    </div>


                    <div className='mb-5'>
                        <h4 className='text-primary'>WHAT RIGHTS DO I HAVE?</h4>
                        <p>You can have control over the data provided, including the ability to request your rights to access, rectify and delete, restrict the processing, transfer your data, withdraw your consent and to object certain processing (i.e unsubscribe from our newsletter). You may send us an email to privacy@{Config.domain} to request your rights. You can exercise your rights in you subscribed to Disqus here.</p>
                        <p>You can as well unsubscribe from receiving our newsletter at any time by clicking the relevant button at the bottom of any email we send you.</p>
                        <p>If you have subscribed to our web notification service you can opt-out at any time via your web browser following these steps</p>
                        <p>Please note that the exercise of your right to withdraw consent will not affect the lawfulness of processing based on your consent before withdrawal.</p>
                        <p>We will process your data to create a profile that will include the data you provide, along with data related to your behaviour in the web site, how you react to our email campaigns but will not be used for automatic individual decision-making purposes. This profile will be used to show you customized content and ads that will be more relevant to you. You can also opt-out from this type of processing at any time via the cookies configuration button located at the bottom right side of our site.</p>
                        <p>Visit the Spanish National Data Protection Authority website to learn more about your rights. Be informed that you have the right to lodge a complaint before the Spanish National Data Protection Authority especially if we have failed to comply with your request to exercise your rights.</p>
                    </div>


                    <div className='mb-5'>
                        <h4 className='text-primary'>IS MY DATA SECURED?</h4>
                        <p>We have adopted adequate technical and organizational measures to avoid alteration, loss or unauthorized access to your data: We select only trusted and certified providers that process your data and we take care that they have taken strict measures too. We and our providers also use encryption protocols to access or transfer your data and techniques to anonymize data to mitigate the risks in case of a data leak.</p>
                    </div>


                    <div className='mb-5'>
                        <h4 className='text-primary'>HOW CAN I KNOW IF YOU CHANGE THIS POLICY?</h4>
                        <p>We will inform you sufficiently in advance if there are important changes and require your consent if any of those changes impact your privacy rights.</p>
                    </div>
                </section>
            </div>
        </main>
    );
}