import {useEffect, useState} from "react";
import {Link, useParams, useSearchParams} from "react-router-dom";
import Config from "../config";
import ReactPaginate from 'react-paginate';
import Skeleton from 'react-loading-skeleton'

function articleItem(){
    return (
        <div className="col-lg-4 col-md-6">
            <div className="item">
                <div className="item-head">
                    <div className="avatar">
                        <Skeleton className="image" width={'55px'} height={'55px'} />
                    </div>
                    <div className="info">
                        <h3 className="title"><Skeleton width="150px" height="23px" /></h3>
                        <div className="stats">
                            <Skeleton width="100px" height="20px" />
                        </div>
                    </div>
                </div>
                <div className="item-body">
                    <div className="description">
                        <Skeleton count={3} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function Category() {
    const {slug} = useParams();
    const [searchParams] = useSearchParams();
    const [pagingInfo, setPagingInfo] = useState({current: searchParams.get("page") || 1, range: 5, count: 1});
    useEffect(() => {
        loadData();
    }, [slug]);

    const [object, setObject] = useState([]);
    const loadData = () => {
        fetch(Config.apiDomain + "category/index/" + slug + "?page=" + (pagingInfo.current))
            .then((res) => res.json())
            .then((json) => {
                setObject(json.data);
                const opts = json.optional;
                setPagingInfo({...pagingInfo, current: opts.paging.page, count: opts.paging.totalPage});
                document.title = json.data.title + ' | ' + Config.companyName;;

            }).finally(() => {
        })
            .catch(error => {
                window.location.assign("/");
            });
    }
    const handlePageClick = (event) => {
        let newObj = pagingInfo;
        newObj.current = event.selected + 1;
        setPagingInfo(newObj)
        loadData();
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('page', newObj.current.toString());
        //window.history.replaceState({}, '', currentUrl.href);
        window.scrollTo(0, 0)
    };

    return (
        <main id="details">
            <div className="container">
                {object.article ? (
                    <h1 className="page-title">{object.title}</h1>
                ) : (
                    <h1 className='page-title'>
                        <Skeleton height='50px' width='300px' />
                    </h1>
                )}

                <div className="regular-app-list border-list mb-5">
                    {object.article ? (
                        <div className="row gx-4 gy-4">
                            {object.article && object.article.map((item, idx) => (
                                <div className="col-lg-4 col-md-6" key={idx}>
                                    <Link className="item" to={item.link}>
                                        <div className="item-head">
                                            <div className="avatar">
                                                <img src={item.avatar} alt={item.title}/>
                                            </div>
                                            <div className="info">
                                                <h3 className="title">{item.title}</h3>
                                                <div className="stats">
                                                    {item.download_count} downloads
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-body">
                                            <div className="description">
                                                {item.description}
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="row gx-4 gy-4">
                            { articleItem() }
                            { articleItem() }
                            { articleItem() }
                            { articleItem() }
                            { articleItem() }
                            { articleItem() }
                            { articleItem() }
                            { articleItem() }
                            { articleItem() }
                            { articleItem() }
                            { articleItem() }
                            { articleItem() }
                        </div>
                    )}
                </div>
                {object.article ? (
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        disableInitialCallback={true}
                        onPageChange={handlePageClick}
                        initialPage={pagingInfo.current - 1}
                        pageRangeDisplayed={pagingInfo.range}
                        pageCount={pagingInfo.count}
                        previousClassName={"page-item"}
                        nextClassName={"page-item"}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        className={"pagination pagination-custom justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousLinkClassName={"page-link"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                ) : (
                    <Skeleton height={'42px'} />
                )}
            </div>
        </main>
    );
}
