import {Link, useSearchParams} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import Swal from 'sweetalert2'
import {useState} from "react";
import Config from "../config";



export default function Footer(){
    const [searchParams] = useSearchParams();
    const [emailNewsletter, setEmailNewsletter] = useState("");

    function isValidEmail(email) {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    }

    function acceptEmailNewsletter(){
        if (isValidEmail(emailNewsletter)) {
            Swal.fire({
                text: 'Thank you for subscribing to our newsletter!',
                icon: 'success',
            })
            setEmailNewsletter("");
        } else {
            Swal.fire({
                text: 'Please enter a valid email address!',
                icon: 'warning',
            })
        }
    }

    return (
        <footer className="footer position-relative">
            <div className="footer-frame__2">
                <div className="footer-frame__2-container">
                    <div className="frame-image"></div>
                </div>
            </div>
            <div className="footer-frame__1">
                <div className="footer-frame__1-container">
                    <div className="frame-image"></div>
                </div>
            </div>
            <div className="container footer-container">
                <div
                    className="d-flex flex-column flex-lg-row justify-content-between footer-header"
                >
                    <div className="footer-header__left">
                        <div className="footer-header__left-logo">
                            <a href="#home" className="logo-wrapper">
                                <img src="/assets/images/logo.png" alt="logo image"/>
                            </a>
                        </div>
                        <p className="footer-header__left-address">
                            <strong>Address:</strong> {Config.address}
                        </p>
                        <div className="d-flex flex-column flex-md-row footer-header__left-contact"
                        >
                            <a href="tel:+1(656) 382-2179" className="contact-item">
            <span className="contact-item__icon">
              <i className="bi bi-telephone-fill"></i>
            </span>
                                {Config.phone}
                            </a>
                            <a href="mailto:support@example.com" className="contact-item">
            <span className="contact-item__icon">
              <i className="bi bi-envelope-fill"></i>
            </span>
                                {Config.email}
                            </a>
                        </div>
                    </div>
                    <div className="footer-header__right">
                        <div className="footer-header__right-links">
                            <div
                                className="d-flex justify-content-between align-items-center link-item"
                            >
                                <HashLink to="/#home" className="link-item__text">Home</HashLink>
                                <Link to="/contact-us" className="link-item__text">Contact Us</Link>
                            </div>
                            <div
                                className="d-flex justify-content-between align-items-center link-item"
                            >
                                <HashLink to="/#features" smooth className="link-item__text">Features</HashLink>
                                <Link to="/dmca-policy" className="link-item__text">DMCA Policy</Link>
                            </div>
                            <div
                                className="d-flex justify-content-between align-items-center link-item"
                            >
                                <HashLink to="/#faq" smooth className="link-item__text">FAQ</HashLink>
                                <Link to="/game-policy" className="link-item__text">Game Policy</Link>
                            </div>
                        </div>
                        <div className="d-flex footer-header__right-subscribe">
                            <input type="email" className="input-custom" placeholder="Email" value={emailNewsletter || ''} onChange={e => setEmailNewsletter(e.target.value)} />
                            <button type="button" onClick={acceptEmailNewsletter} className="subscribe-btn btn btn-primary">Send</button>
                        </div>
                    </div>
                </div>
                <div
                    className="d-flex flex-column flex-md-row justify-content-md-between footer-bottom"
                >
                    <p className="text-center text-md-start footer-bottom__text">
                        © 2024 {Config.companyName}
                    </p>
                    <div
                        className="d-flex justify-content-center align-items-center mt-3 mt-md-0 footer-bottom__socials"
                    >
                        <a href="#" className="social-item">
                            <i className="bi bi-facebook facebook"></i>
                        </a>
                        <a href="#" className="social-item">
                            <i className="bi bi-youtube youtube"></i>
                        </a>
                        <a href="#" className="social-item">
                            <i className="bi bi-instagram instagram"></i>
                        </a>
                        <a href="#" className="social-item">
                            <i className="bi bi-linkedin linkedin"></i>
                        </a>
                    </div>
                    <div
                        className="d-flex justify-content-center align-items-center mt-3 mt-md-0 footer-bottom__links"
                    >
                        <Link to="/terms-of-use" className="link-item">Terms</Link>
                        <Link to="/privacy-policy" className="link-item">Privacy</Link>
                        <Link to="/cookies-policy" className="link-item">Cookies</Link>
                    </div>
                </div>
            </div>

            <div
                className="modal fade search-modal"
                id="searchModal"
                tabIndex="-1"
                aria-labelledby="searchModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-transparent border-0">
                        <div className="modal-header modal-close border-0">
                            <i className="bi bi-x" data-bs-dismiss="modal" aria-label="Close"></i>
                        </div>
                        <div className="modal-body search-modal-body">
                            <form method="get" action="/search">
                                <div className="search-modal-body__input">
                                    <input
                                        type="text"
                                        name="query"
                                        id="searchText"
                                        placeholder="Search"
                                        defaultValue={searchParams.get("query")}
                                    />
                                    <button type="submit" className="submit-icon"><i className="bi bi-search"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
