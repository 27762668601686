import {useEffect, useState} from "react";
import Config from '../config.js';
import {Link} from "react-router-dom";
import {HashLink} from "react-router-hash-link";

export default function Home() {
    const [categories, setCategories] = useState([]);
    const [categoryTabActive, setCategoryTabActive] = useState(0);

    useEffect(() => {
        fetch(Config.apiDomain + "home")
            .then((res) => res.json())
            .then((json) => {
                setCategories(json.data);
            });

        document.title = 'Free Download Best PC Games | Blue Games';
    }, []);

    return (
        <main id="home">
            <section className="position-relative d-flex flex-column align-items-center banner-section">
                <div className="container banner-section__container">
                    <div className="banner-section__title">
                        <h1 className="title-text">
                            Free Download Best PC Games
                        </h1>
                        <h6 className="description-text">
                            Discover and download a vast library of games, from popular classics to the latest releases.
                        </h6>
                    </div>

                    {/*<div className="banner-section__describe">
                        <div className="banner-section__describe-item">
                            <div className="describe-icon">
                                <i className="bi bi-cloud-download-fill"></i>
                            </div>
                            <p className="describe-text">High speed CDN Server Worldwide</p>
                        </div>

                        <div className="banner-section__describe-item">
                            <div className="describe-icon">
                                <i className="bi bi-cloud-download-fill"></i>
                            </div>
                            <p className="describe-text">Many useful programs in our category</p>
                        </div>
                    </div>*/}

                    <div className="w-100 d-flex justify-content-center align-items-center banner-section__cta">
                        <HashLink to="/#products" smooth className="btn btn-lg btn-custom btn-primary">View Featured Games&nbsp; <i className="bi bi-chevron-down"></i></HashLink>
                    </div>
                </div>

                <div className="banner-image"></div>
                <div className="setting-icon-1">
                    <i className="bi bi-dpad"></i>
                </div>
                <div className="setting-icon-2">
                    <i className="bi bi-joystick"></i>
                </div>
                <div className="left-side-frame"></div>
                <div className="right-side-frame"></div>
            </section>

            <section id="products" className="position-relative products-section pb-5">
                <div className="container products-section__container">
                    <div className="mx-auto section-title">
                        <div className="section-title__text">
                            <h2 className="title-text text-center">Explore Our Featured Games</h2>
                            <h6 className="description-text text-center">
                                Dive into our curated selection of featured PC games
                            </h6>
                        </div>
                    </div>

                    <div className="product-section__body">
                        <div className="product-section__body-cta">
                            {categories.map((category, key) => (
                            <button
                                type="button"
                                className={`btn-custom btn-secondary ${categoryTabActive === key ? 'active' : ''}`}
                                data-bs-target="#productsSectionCarousel"
                                data-bs-slide-to={key}
                                onClick={() => setCategoryTabActive(key)}
                                key={key}
                            >
                                {category.title}
                            </button>
                            ))}
                        </div>
                        <div className="product-section__body-detail">
                            <div
                                id="productsSectionCarousel"
                                className="carousel slide"
                                data-bs-interval="false"
                            >
                                <div className="carousel-inner">
                                    {categories.map((category, key) => (
                                    <div className={`carousel-item ${categoryTabActive === key ? 'active' : ''}`} key={key}>
                                        <div className="pc-games-slide">
                                            {category.article && category.article.map((item, idx) => (
                                                <Link className="pc-games-slide__item" to={item.link} key={idx}>
                                                    <div className="item-header">
                                                        <div className="item-header__avatar">
                                                            <img src={item.avatar} alt={item.title}/>
                                                        </div>
                                                        <div className="item-header__detail">
                                                            <h6 className="game-name">{item.title}</h6>
                                                            <div className="d-flex">
                                                                <p className="game-downloaded">{item.download_count} downloads</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="item-body-text">{item.description}</p>
                                                </Link>
                                            ))}
                                        </div>
                                        <div className="text-center text-uppercase">
                                            <Link className="btn btn-primary" to={category.link}>View more <i className="bi bi-arrow-right-circle"></i></Link>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="product-section__frame"></div>
            </section>

            <section id="features" className="position-relative features-section">
                <div className="features-section__frame frame-1">
                    <div className="frame-item"></div>
                </div>
                <div className="features-section__frame frame-2">
                    <div className="frame-item"></div>
                </div>
                <div className="container features-section__container">
                    <div className="section-title features-section__title">
                        <div className="section-title__text">
                            <h2 className="title-text">
                                Discover the Blue Games Difference
                            </h2>
                            <p className="description-text">
                                Explore the exceptional features of Blue Games
                            </p>
                        </div>
                    </div>
                    <div className="features-section__body">
                        <div className="features-section__body-item box-1">
                            <div className="item-title justify-content-lg-end">
                                <h6 className="order-2 order-lg-1 ms-2 me-lg-2 title">Free Download Access</h6>
                                <span className="order-1 order-lg-2 title-icon">
                            <i className="bi bi-cloud-download-fill"></i>
                        </span>
                            </div>
                            <p className="text-lg-end item-description">
                                Enjoy unlimited access to a diverse collection of PC games completely free. Discover, download, and play your favorite titles without any cost.
                            </p>
                        </div>
                        <div className="features-section__body-item box-2">
                            <div className="justify-content-start item-title">
                    <span className="title-icon">
                      <i className="bi bi-folder-fill"></i>
                    </span>
                                <h6 className="ms-2 title">Extensive Game Library</h6>
                            </div>
                            <p className="text-start item-description">
                                Explore our game library featuring thousands of popular games. From action adventures to immersive simulations, we have something for every gamer.
                            </p>
                        </div>
                        <div className="features-section__body-item box-3">
                            <div className="item-title justify-content-lg-end">
                                <h6 className="order-2 order-lg-1 ms-2 me-lg-2 title">Fast Downloads</h6>
                                <span className="order-1 order-lg-2 title-icon">
                          <i className="bi bi-lightning-fill"></i>
                        </span>
                            </div>
                            <p className="text-lg-end item-description">
                                Experience lightning-fast download speeds when you get games from us. Our optimized servers ensure that you spend less time waiting and more time gaming.
                            </p>
                        </div>
                        <div className="features-section__body-item box-4">
                            <div className="justify-content-start item-title">
                        <span className="title-icon">
                          <i className="bi bi-shield-fill-check"></i>
                        </span>
                                <h6 className="ms-2 title">Trusted Source</h6>
                            </div>
                            <p className="text-start item-description">
                                Count on us for secure and reliable game downloads. We source our games from reputable publishers and ensure that every download is safe.
                            </p>
                        </div>
                        <div className="d-none d-lg-flex features-section__body-circle">
                            <img
                                src="./assets/images/logo-icon.png"
                                alt="features section circle icon"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section id="faq" className="position-relative faq-section">
                <div className="container faq-section__container">
                    <div className="section-title text-center">
                        <div className="section-title__text">
                            <h2 className="text-center title-text">
                                Frequently Asked Questions
                            </h2>
                            <h6 className="text-center description-text">
                                Find quick answers to common questions about Blue Games
                            </h6>
                        </div>
                    </div>
                    <div className="faq-section__body">
                        <div className="faq-section__body-left accordion">
                            <div className="faq-item accordion-item">
                                <button
                                    className="faq-item__btn accordion-button collapsed"
                                    type="button"
                                    aria-controls="faqQuestion1"
                                    aria-label="faqButton"
                                    data-bs-toggle="collapse" data-bs-target="#faqQuestion1" aria-expanded="true" aria-controls="faqQuestion1"
                                >
              <span className="left-side">
                <span className="number">1</span>
                <span className="text">Are the download links safe and secure?</span>
              </span>
                                </button>
                                <div className="collapse" id="faqQuestion1">
                                    <div className="card card-body border-0 pb-0 bg-transparent">
                                        Yes, we ensure that all download links provided through our website are safe and secure.
                                    </div>
                                </div>
                            </div>
                            <div className="faq-item accordion-item">
                                <button
                                    className="faq-item__btn accordion-button collapsed"
                                    type="button"
                                    aria-controls="faqQuestion2"
                                    aria-label="faqButton"
                                    data-bs-toggle="collapse" data-bs-target="#faqQuestion2" aria-expanded="true" aria-controls="faqQuestion2"
                                >
              <span className="left-side">
                <span className="number">2</span>
                <span className="text">Are the game downloads on Blue Games legal?</span>
              </span>
                                </button>
                                <div className="collapse" id="faqQuestion2">
                                    <div className="card card-body border-0 pb-0 bg-transparent">
                                        Yes, all game downloads offered on Blue Games are sourced from legitimate and authorized distributors. We ensure that our downloads comply with copyright laws.
                                    </div>
                                </div>
                            </div>
                            <div className="faq-item accordion-item">
                                <button
                                    className="faq-item__btn accordion-button collapsed"
                                    type="button"
                                    aria-controls="faqQuestion3"
                                    aria-label="faqButton"
                                    data-bs-toggle="collapse" data-bs-target="#faqQuestion3" aria-expanded="true" aria-controls="faqQuestion3"
                                >
              <span className="left-side">
                <span className="number">3</span>
                <span className="text">Is Blue Games free to use?</span>
              </span>
                                </button>
                                <div className="collapse" id="faqQuestion3">
                                    <div className="card card-body border-0 pb-0 bg-transparent">
                                        Yes, Blue Games is completely free to use. You can browse our collection of games and download them without any cost.
                                    </div>
                                </div>
                            </div>
                            <div className="faq-item accordion-item">
                                <button
                                    className="faq-item__btn accordion-button collapsed"
                                    type="button"
                                    aria-controls="faqQuestion4"
                                    aria-label="faqButton"
                                    data-bs-toggle="collapse" data-bs-target="#faqQuestion4" aria-expanded="true" aria-controls="faqQuestion4"
                                >
              <span className="left-side">
                <span className="number">4</span>
                <span className="text">Do I need to register or create an account to download?</span>
              </span>
                                </button>
                                <div className="collapse" id="faqQuestion4">
                                    <div className="card card-body border-0 pb-0 bg-transparent">
                                        No registration or account creation is required to download games from Blue Games. Simply visit our website and start downloading your favorite games.
                                    </div>
                                </div>
                            </div>
                            <div className="faq-item accordion-item">
                                <button
                                    className="faq-item__btn accordion-button collapsed"
                                    type="button"
                                    aria-controls="faqQuestion5"
                                    aria-label="faqButton"
                                    data-bs-toggle="collapse" data-bs-target="#faqQuestion5" aria-expanded="true" aria-controls="faqQuestion5"
                                >
              <span className="left-side">
                <span className="number">5</span>
                <span className="text">Is there a limit to the number of games I can download?</span>
              </span>
                                </button>
                                <div className="collapse" id="faqQuestion5">
                                    <div className="card card-body border-0 pb-0 bg-transparent">
                                        There is no limit to the number of games you can download from Blue Games. Feel free to explore our collection and download as many games as you like.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-none d-xl-block faq-section__body-right">
                            <div className="position-absolute w-100 h-100 start-0 top-0">
                                <div className="faq-section__body-right__container">
                                    <div className="banner-image__1">
                                        <div className="banner-image__1-container">
                                            <div className="image-background"></div>
                                        </div>
                                    </div>
                                </div>

                                {/*<div className="faq-section__body-right__container">
                                    <div className="banner-image__2">
                                        <div className="banner-image__2-container">
                                            <div className="image-background"></div>
                                        </div>
                                    </div>
                                </div>*/}

                                {/*<div className="faq-section__body-right__container">
                                    <div className="banner-image__3">
                                        <div className="banner-image__3-container">
                                            <div className="image-background"></div>
                                        </div>
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="faq-frame-left">
                    <div className="faq-frame-left__container">
                        <div className="faq-frame-left__body"></div>
                    </div>
                </div>
                <div className="faq-frame-right"></div>
            </section>
        </main>
        /*<div>
            <section className="section" id="home-header">
                <div className="container">
                    <div className="d-flex align-items-center">
                        <div className="col-left">
                        <span className="note note-primary">
                            <span className="badge text-bg-primary">NEW</span> The best programs for everyone
                        </span>
                            <h1>Download popular and useful softwares for your PC</h1>
                            <p className="caption">
                                Download your favorite software completely FREE<br/>without registration!
                            </p>
                            <div className="action-btn">
                                <a href="#home-catalog" className="btn btn-primary mb-2 me-3">Go to catalog <i className="bi bi-chevron-right"></i></a>
                            </div>
                            <div className="features d-flex">
                                <div className="item">
                                    <div className="image"><img src="/assets/images/icons/icon-file.svg"/></div>
                                    <h4 className="title">Fast Download</h4>
                                    <div className="caption">Download with high speed CDN Server</div>
                                </div>
                                <div className="item">
                                    <div className="image"><img src="/assets/images/icons/icon-cloud.svg"/></div>
                                    <h4 className="title">More Programs</h4>
                                    <div className="caption">Many useful programs in our category</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-right">
                            <img src="/assets/images/misc/soft-list-1.png" alt="Blue Softs"/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section pb-2" id="home-catalog">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="col-left">
                            <h2 className="section-title text-start">
                                The best programs<br/>
                                by <span className="underlines">Blue Softs</span>
                            </h2>
                            <p className="section-caption text-start">
                                Choose, download and enjoy programs completely free now!
                            </p>
                        </div>
                        <div className="col-right">
                            <img src="/assets/images/misc/soft-list-2.png" alt=""/>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        {categories.map((category, key) => (
                            <div key={key} className={key === 0 ? 'col-12 mb-5' : 'col-6 mb-5'}>
                                <h2 className="catalog-title">{category.title}</h2>
                                <div className="catalog-soft-list">
                                    {category.article && category.article.map((item, idx) => (
                                        <div className="item" key={idx}>
                                            <div className="details">
                                                <div className="image"><Link to={item.link}><img src={item.avatar} alt={item.title}/></Link></div>
                                                <div className="info">
                                                    <Link to={item.link}><h3 className="title">{item.title}</h3></Link>
                                                    <p className="description">{item.description}</p>
                                                </div>
                                            </div>
                                            <div className="action">
                                                <Link to={item.link} className="download_btn btn btn-primary">Details</Link>
                                                <span className="count"> {item.download_count} downloads</span>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="text-center mt-3 text-uppercase">
                                        <Link to={category.link}>View more <i className="bi bi-arrow-down-circle"></i></Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className="section" id="home-features">
                <div className="container">
                    <h2 className="section-title">
                        Why you should download<br/>
                        softwares from <span className="underlines">Blue Softs?</span>
                    </h2>
                    <p className="section-caption">
                        All software has been carefully selected and evaluated by us, you can use it with confidence.
                    </p>
                    <div className="features-list custom-card custom-card-primary position-relative">
                        <div className="d-flex justify-content-between">
                            <div className="col-left">
                                <div className="item margin-item d-flex flex-wrap flex-column">
                                    <div className="title d-flex flex-wrap align-items-center">
                                        <img src="/assets/images/icons/icon-cloud-2.svg" alt=""/>
                                        <h4 className="text">Free</h4>
                                    </div>
                                    <div className="caption">
                                        On our site there are a lot of<br/>
                                        useful programs that you can<br/>
                                        download for free
                                    </div>
                                </div>

                                <div className="item d-flex flex-wrap flex-column">
                                    <div className="title d-flex flex-wrap align-items-center">
                                        <img src="/assets/images/icons/icon-cloud-2.svg" alt=""/>
                                        <h4 className="text">Fast</h4>
                                    </div>
                                    <div className="caption">
                                        You can download programs<br/>
                                        absolutely free and at high speed<br/>
                                        from anywhere in the world
                                    </div>
                                </div>
                            </div>
                            <div className="col-right">
                                <div className="item margin-item d-flex flex-wrap flex-column">
                                    <div className="title d-flex flex-wrap align-items-center justify-content-end">
                                        <img src="/assets/images/icons/icon-cloud-2.svg" alt=""/>
                                        <h4 className="text">Large</h4>
                                    </div>
                                    <div className="caption text-end">
                                        Our category of programs<br/>
                                        contains a large number of<br/>
                                        programs for download
                                    </div>
                                </div>

                                <div className="item d-flex flex-wrap flex-column">
                                    <div className="title d-flex flex-wrap align-items-center justify-content-end">
                                        <img src="/assets/images/icons/icon-cloud-2.svg" alt=""/>
                                        <h4 className="text">Trust</h4>
                                    </div>
                                    <div className="caption text-end">
                                        We are trusted by many users<br/>
                                        from all over the world and<br/>
                                        we value our reputation
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section" id="home-faq">
                <div className="container">
                    <h2 className="section-title">
                        Frequently Asked <span className="underlines">Questions</span>
                    </h2>
                    <p className="section-caption">
                        Answers to common questions regarding our website.
                    </p>
                    <div className="faq-block custom-card custom-card-primary position-relative">
                        <div className="icon-top-left position-absolute"><img src="/assets/images/misc/faq-top-left.png" alt=""/></div>
                        <div className="icon-top-right position-absolute"><img src="/assets/images/misc/faq-top-right.png" alt=""/></div>
                        <div className="accordion accordion-custom accordion-flush" id="home-faq-list">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        Is it true that all the programs on the site are free?
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#home-faq-list">
                                    <div className="accordion-body">
                                        We provide an opportunity to download all programs absolutely free - without SMS and registration as well as at high speed.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        What programs are on the site?
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#home-faq-list">
                                    <div className="accordion-body">
                                        Our site features programs in a wide variety of categories - from Multimedia to Business.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        How can I download programs?
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#home-faq-list">
                                    <div className="accordion-body">
                                        You can download any desired program from our website by clicking on the "Download" button.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                        What is the download speed?
                                    </button>
                                </h2>
                                <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#home-faq-list">
                                    <div className="accordion-body">
                                        We use a content delivery network (CDN) server around the world - so you will get the fastest download speed!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>*/
    );
}
