import React, {useEffect, useState} from "react";
import {Link, useParams, useSearchParams} from "react-router-dom";
import Config from "../config";
import ReactPaginate from 'react-paginate';
import Skeleton from 'react-loading-skeleton'

function articleItem(){
    return (
        <div className="col-lg-4 col-md-6">
            <div className="item">
                <div className="item-head">
                    <div className="avatar">
                        <Skeleton className="image" width={'55px'} height={'55px'} />
                    </div>
                    <div className="info">
                        <h3 className="title"><Skeleton width="150px" height="23px" /></h3>
                        <div className="stats">
                            <Skeleton width="100px" height="20px" />
                        </div>
                    </div>
                </div>
                <div className="item-body">
                    <div className="description">
                        <Skeleton count={3} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function Search() {
    const {slug} = useParams();
    const [searchParams] = useSearchParams();
    const [pagingInfo, setPagingInfo] = useState({current: searchParams.get("page") || 1, range: 5, count: 1});
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    useEffect(() => {
        loadData();
    }, [slug]);

    const [object, setObject] = useState([]);
    const [noresult, setNoresult] = useState(false);
    const loadData = () => {
        fetch(Config.apiDomain + "article/search?q=" + (searchParams.get("query")) + '&page=' + (pagingInfo.current))
            .then((res) => res.json())
            .then((json) => {
                setObject(json.data);
                if(json.data.length === 0) setNoresult(true);
                const opts = json.optional;
                setPagingInfo({...pagingInfo, current: opts.paging.page, count: opts.paging.totalPage});
                document.title = 'Search: ' + searchParams.get("q");
            }).finally(() => {
            setIsFirstLoad(false);
        });
    }
    const handlePageClick = (event) => {
        if (!isFirstLoad) {
            let newObj = pagingInfo;
            newObj.current = event.selected + 1;
            setPagingInfo(newObj)
            loadData();
            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.set('page', newObj.current.toString());
            window.history.replaceState({}, '', currentUrl.href);
        }
    };

    return (
        <main id="details">
            <div className="container">
                <h1 className="page-title">Search: {searchParams.get("query")}</h1>

                <div className="regular-app-list border-list mb-5">
                    {object.article ? (
                        <div className="row gx-4 gy-4">
                            {object.article && object.article.map((item, idx) => (
                                <div className="col-lg-4 col-md-6" key={idx}>
                                    <Link className="item" to={item.link}>
                                        <div className="item-head">
                                            <div className="avatar">
                                                <img src={item.avatar} alt={item.title}/>
                                            </div>
                                            <div className="info">
                                                <h3 className="title">{item.title}</h3>
                                                <div className="stats">
                                                    {item.download_count} downloads
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-body">
                                            <div className="description">
                                                {item.description}
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="row gx-4 gy-4">
                            { noresult === true ? (
                                <div className='text-center'>
                                    <div className='mb-3 text-danger'>
                                        No result!
                                    </div>
                                    <div>
                                        <Link to="/" className="btn btn-primary">
                                            Go to Homepage
                                        </Link>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    { articleItem() }
                                    { articleItem() }
                                    { articleItem() }
                                    { articleItem() }
                                    { articleItem() }
                                    { articleItem() }
                                    { articleItem() }
                                    { articleItem() }
                                    { articleItem() }
                                    { articleItem() }
                                    { articleItem() }
                                    { articleItem() }
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {object.article ? (
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={(p) => {
                            handlePageClick(p);
                        }}
                        initialPage={pagingInfo.current - 1}
                        pageRangeDisplayed={pagingInfo.range}
                        pageCount={pagingInfo.count}
                        previousClassName={"page-item"}
                        nextClassName={"page-item"}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        className={"pagination pagination-custom justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousLinkClassName={"page-link"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                ) : (
                    <Skeleton height={ noresult === true ? '0px' : '42px'} />
                )}
            </div>
        </main>
    );
}
