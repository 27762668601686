import Config from "../../config";
import {Link} from "react-router-dom";

export default function CookiesPolicy() {
    document.title = 'Cookies Policy';
    return (
        <main id="static-page">
            <div className='container'>
                <h1 className="page-title">Cookies Policy</h1>
                <div className='mb-5'>
                    <h4 className='text-primary'>WHEN THIS POLICY APPLIES?</h4>
                    <p>This policy applies when accessing {Config.domain}, its subdomains and other web sites where this Cookies Policy is referred or made accessible for you to read and accept. This Cookies Policy forms part of our general <Link to='/privacy-policy'>Privacy Policy</Link>.</p>
                    <p>We have created this Cookies Policy to provide you with clear and explicit information about cookies and other technologies that we and our trusted partners use on the website and your choices when it comes to these technologies.</p>
                    <p>By accessing the website for the first time, without previously having cookies from this website, you will see a window informing you of the use of cookies and where you can view this Cookies Policy. If you click the accept button, you agree / consent to the use of cookies and these will be installed on your computer or device, otherwise cookies will not be installed and browsing and the service of this website may be limited .</p>
                    <p>If you consent to the use of cookies and other technologies and to our and third parties’ use, in accordance with this policy, you agree of any personal information that we and our trusted partners collect using these technologies. You may opt-out and change your cookie preferences later at any time by accessing our Cookies Settings tool that will be always available on the home page of the website. See the section, “how can I opt-out to the installation of cookies and other technologies?” below for more information.</p>
                    <p>If you do not consent to the use of cookies and other technologies, please configure your preferences on our Cookies Settings or block or disable them using your browser settings, the opt-out links identified in this policy, or the settings within your device.</p>
                    <p>If you accept cookies you consent to the possible processing of personal data for all purposes indicated, international transfers and communications; but if you do not consent to such use then please do not accept the use of cookies.</p>
                </div>


                <div className='mb-5'>
                    <h4 className='text-primary'>WHAT ARE COOKIES AND WHAT OTHER TECHNOLOGIES ARE YOU USED?</h4>
                    <p><strong>Cookies</strong> are text files of a small size, often encrypted, that a website stores on a computer or other devices when accessing the website. Cookies may store or allow us and/or third parties to access certain non-identifiable and anonymous information about you. When you revisit the site cookies may be read to set the preferences and browser settings for you. Cookies can be “persistent” or “session” cookies. Persistent cookies remain on your computer when you have gone offline, while session cookies are deleted as soon as you close your web browser. To find out more about cookies, including how to see what cookies have been set and how to block and delete cookies, please visit <a target='_blank' rel='nofollow noreferrer' href='https://href.li/?https://knowcookies.com/'>https://knowcookies.com/</a></p>
                    <p><strong>Pixels:</strong> A pixel is code that placed on the website. It helps to track conversions from ads, optimize ads based on collected data, build targeted audiences for future ads, and remarket on pixels’ third party (such as third party sites and social media) to qualified leads—users who have already taken some kind of action on the website- learn more about pixels <a target='_blank' rel='nofollow noreferrer' href='https://href.li/?https://skillcrush.com/2012/07/19/tracking-pixel/'>here</a></p>
                    <p><strong>Tags:</strong> Ad Tags are the HTML code a browser uses to fetch an advertisement from an Ad Server – it is a redirect to content rather than content itself. Learn more about tags <a target='_blank' rel='nofollow noreferrer' href='https://href.li/?https://wiki.appnexus.com/display/industry/Ad+Tags'>here</a></p>
                    <p><strong>Javascript:</strong> This script is implemented in the source code of our website. We use it to create interactive effects within web browsers but we do not collect any personal data by means of this technology.</p>
                    <p>Note that failure to allow the use of cookies and other technologies means that certain services or functionalities of the website will not be available to the user and/or will not work properly.</p>
                </div>


                <div className='mb-5'>
                    <h4 className='text-primary'>WHAT TYPES OF COOKIES DOES WE USE AND WHAT FOR?</h4>
                    <p><strong>Technical and UI Customization cookies</strong> to store your preferences across web pages (e.g. language) and not linked to other persistent identifiers. These cookies are always active as are strictly necessary for you to have an adequate experience on the website. Technical cookies are used to provide certain functionalities such as keeping track of your input when filling online forms, keeping track of the items the user has selected by clicking on a button, securing the service, storing technical data needed to play back video or audio content, integrating social plug-in modules and to authenticate you upon log on to pages that requires identification / authentication. These cookies are always active as are strictly necessary to provide you with what you would expect as an acceptable service behaviour of any website.</p>
                    <p><strong>Analytics cookies</strong> (such as our own and those of Google, ComScore or Hotjar). These cookies are used to analyse the visits made, places, behaviour and times of website navigation. The cookie information is transmitted to and stored directly by the analytical providers on their servers. These cookies are used anonymously, in aggregate form, to prepare website trend reports and analyse traffic to finally enhance your experience.</p>
                    <ul>
                        <li>Google Analytics is service provided by Google, Inc. Google Analytics uses cookies to help us analyse how users use our website. The information generated by these cookies (including your truncated IP address) is transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your, and other users’, use of our website, compiling reports for us on website activity and providing other services relating to website activity and internet usage. Please note that Google only receives your truncated IP address. This is sufficient for Google to identify (approximately) the country from which you are visiting our sites, but is not sufficient to identify you, or your computer or mobile device, individually. The maximum retention period of the data is 14 months. You can find more information <a target='_blank' rel='nofollow noreferrer' href='https://href.li/?https://www.google.com/analytics/learn/privacy.html'>here</a> including a link to <a target='_blank' rel='nofollow noreferrer' href='https://href.li/?https://www.google.com/intl/en/policies/privacy/'>Google’s privacy policy</a>.</li>
                        <li>Hotjar is a service provided by Hotjar Ltd a company located in Malta (EU). It helps us to understand how you use and interact our website by visually representing their clicks, taps and scrolling behaviour. We use the service to help understand and interpret user behaviour anonymously, without personally identifying individual users. An automatic 365-day data retention period is enforced to ensure all analytics data older than 365 days collected through Hotjar is systematically deleted. Find more information <a target='_blank' rel='nofollow noreferrer' href='https://href.li/?https://www.hotjar.com/privacy/gdpr-compliance'>here</a>.</li>
                        <li>Datadog is a service provided by Datadog, Inc, a company located in Delaware (U.S). Datadog service provides us with a visualisation on how the users interact with our websites in an aggregated and/or anonymised way with the aim of identifying errors, enhance the service and better monetise our websites.  The maximum retention period of the data is 15 months. Find more information <a target='_blank' rel='nofollow noreferrer' href='https://href.li/?https://www.datadoghq.com/legal/privacy/'>here</a>.</li>
                    </ul>
                    <p><strong>Advertising Cookies.</strong> We use cookies to make advertising more attractive to users. Cookies are often used to select ads based on content that is relevant to a user, improve reporting of ads campaign performance and avoid showing ads that the user has already seen. Conversion cookies are also used so that advertisers may determine the number of times that people who click on the ads end up buying their products. These cookies allow we and the advertiser to know that you have clicked on the ad and that you have subsequently accessed the site of the advertiser.</p>
                    <p>To serve you interest-based ads, we use information such as your interactions with our sites, content, or services. We do not use personally identifiable information such as name or e-mail to serve interest-based ads. In serving you interest-based ads we only keep information collected for as long as required to provide our advertising services.</p>
                    <p>We and our partners use the information collected by means of these cookies to deliver content, including ads, relevant to your interests on our sites and third-party sites. We work with DoubleClick, a platform provided by Google Ireland Limited, which helps us gather information about our audience, helping us to manage the ads and marketing campaigns that are published on our website (positions, campaign duration, formats, link, tracking, etc). Approved third parties’ ad partners may also set cookies when you interact with us. Third parties include social media networks and advertising companies. Third parties use cookies in the process of delivering content, including ads relevant to your interests, to measure the effectiveness of their ads, and to perform services on behalf of us. You can always check the third parties that set cookies and set your consent preferences for every partner listed individually by clicking “Cookie Settings” at the bottom of the page and then “View our partners“: Click on a partner name to get more information on what it does, what data it is collecting and how it is using it.</p>
                    <p>We work with third parties, such as advertisers, publishers, social media networks, search engines, ad serving companies, and advertising companies working on their behalf, to improve the relevance of ads we serve. In providing you interest-based ads we do not associate your interactions on unaffiliated sites with personally identifiable information, and we do not provide any personally identifiable information to advertisers or to third party sites that display our interest-based ads. Advertisers and other third parties may assume that users who interact with or click on an interest-based ad or content are part of the group that the ad or content is directed towards (for example, users in a particular geographical area or users who has viewed a page of a particular game). They may automatically receive an IP address when this happens. They may also use cookies to measure the effectiveness of their ads, show you more relevant advertising content, and perform services on behalf of us.</p>
                    <p>We offer you choices about receiving interest-based ads from us. You can choose not to receive interest-based ads from us. You will still see ads, but they will not be based on your interests. Please visit our Cookies settings page at the bottom of the page to learn how to set your preferences.</p>
                    <p>Our website further uses other advertising IDs (such as the ones from Criteo and Facebook pixels, and Liveramp for US). These enable us to show our advertisements to visitors who are interested in our products on partner websites and apps. Re-targeting technologies use your cookies or advertising IDs and display advertisements based on your past browsing behaviour. In particular, Facebook Pixel is placed by Facebook; It is a third-party advertising type of cookie that enables us to measure, optimize and build audiences for advertising campaigns served on Facebook. It enables us to see how our users move between devices when accessing our web site and Facebook, to ensure that our Facebook advertising is seen by our users most likely to be interested in such advertising by analysing which content a user has viewed and interacted with on our web site. Specifically, when you visit a download page or download any game, we share with Facebook the program name visited or downloaded, so based on your behaviour, Facebook will display related ads when visiting Facebook. For further information about the Facebook Pixel please go <a target='_blank' rel='nofollow noreferrer' href='https://href.li/?https://en-gb.facebook.com/business/help/651294705016616.'>here</a>. To read more about the Criteo pixel please go <a target='_blank' rel='nofollow noreferrer' href='https://href.li/?https://www.criteo.com/privacy'>here</a>.</p>
                    <p>Liveramp Pixel (only applicable for the U.S.) helps companies to serve online display media on third party websites and apps based on previous activity, while keeping your data anonymous and secure. Liveramp does not place “tracking” cookies that correlate activity across websites; instead, Liveramp place anonymized demographic and interest data into cookies. Cross-site web-browsing activities are neither tracked nor stored by Liveramp. To learn more about how Liveramp collect and use information for online advertising, please go <a target='_blank' rel='nofollow noreferrer' href='https://href.li/?https://liveramp.com/privacy/'>here</a>.</p>
                    <p>We also use ID5 Universal ID. This technology enables us to create unified anonymous IDs that are unique for each visitor and are used to display personalised advertisements to you by means of a first-party cookie, which may capture your IP address, browser version and/or type , operating system version and / or type. ID5 Universal ID also enforces your privacy preferences and prevents data leakage by ensuring only companies that you have authorized via your consent preference to read or use the ID.</p>
                    <p>Please go to <a target='_blank' rel='nofollow noreferrer' href='https://href.li/?https://www.youronlinechoices.com/'>youronlinechoices.com</a> to learn more about behavioural advertising and to know the steps you can take to protect your privacy on the internet</p>
                    <p>Our website uses JavaScript too to detect the use of ad blocking extensions for web browsers. This script is implemented in the source code of our website. The script simulates the display of an ad and confirms that it is displayed on a user’s device. To do this, we do not store any information on users’ devices and process no personal data. If we detect that a user deploys an ad blocker, we may interact with them on the use of ad blockers and/or adapt the content available to them. If a user no longer wants us to use ad blocker detection mechanisms, they may opt-out by leaving the web site. We reserve the right to restrict access to our websites if users do not agree to the use of ad blocker detection mechanisms.</p>
                </div>


                <div className='mb-5'>
                    <h4 className='text-primary'>WHAT’S THE LEGAL BASIS OF THE PROCESSING?</h4>
                    <p>The legal basis for the processing of your data is your consent.</p>
                </div>

                <div className='mb-5'>
                    <h4 className='text-primary'>HOW CAN I OPT-OUT TO THE INSTALLATION OF COOKIES AND OTHER TECHNOLOGIES?</h4>
                    <p>There are several ways you can opt-out from cookies usage:</p>
                    <ul>
                        <li>To opt out of the data processing performed by Google, please go <a target='_blank' rel='nofollow noreferrer' href='https://href.li/?https://tools.google.com/dlpage/gaoptout'>here</a>.</li>
                        <li>To opt-out of the data processing performed by Hotjar please go <a target='_blank' rel='nofollow noreferrer' href='https://href.li/?https://help.hotjar.com/hc/en-us/articles/360001749014-Visitor-Lookup'>here</a>.</li>
                        <li>To opt-out of the data processing performed by Datadog please fo <a target='_blank' rel='nofollow noreferrer' href='https://href.li/?https://www.datadoghq.com/legal/cookies/'>here</a>.</li>
                        <li>You can set your browser to refuse or accept cookies, or set the browser so that cookies are automatically deleted once you close your browser, computer or device. You may change your cookies settings in browsers as follows:
                            <ul>
                                <li>Google Chrome Settings > Privacy > Content Settings > Cookies</li>
                                <li>Firefox Preferences > Privacy.</li>
                                <li>Internet Explorer under Tools > Internet Options > Privacy > Advanced</li>
                                <li>Safari Preferences > Privacy.</li>
                            </ul>
                        </li>
                    </ul>
                    <p>Please find more information on how to configure your cookies settings <a target='_blank' rel='nofollow noreferrer' href='https://href.li/?https://www.aboutcookies.org/how-to-control-cookies/'>here</a>.</p>
                    <p>Please note that opting out will not affect the lawfulness of processing based on your consent before withdrawal.</p>
                </div>


                <div className='mb-5'>
                    <h4 className='text-primary'>IS THE DATA SECURED?</h4>
                    <p>We and our trusted third parties have adopted adequate technical and organizational measures to avoid alteration, loss or unauthorised access to your data: We select only trusted and certified partners that process your data and we take care that they have taken strict measures too. We and our partners also use encryption protocols to access or transfer your data and anonymize techniques to mitigate the risks in case of a data leak.</p>
                </div>


                <div className='mb-5'>
                    <h4 className='text-primary'>HOW CAN I KNOW IF YOU CHANGE THIS POLICY?</h4>
                    <p>We will inform you sufficiently in advance if there are important changes and require your consent if any of those changes impact your privacy rights.</p>
                </div>
            </div>
        </main>
    )
}