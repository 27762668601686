//let domain =  window.location.hostname;
//const mainDomain = 'media.'+window.location.hostname;
let domain =  'blue-games.net';
const mainDomain = 'media.' + domain;
const Config = {
    apiDomain:"https://"+mainDomain+"/api/",
    domain: domain,
    fullDomain: 'https://' + domain,
    mediaDomain: '//' + mainDomain,
    companyName: 'Blue Games',
    address: '1766 Saint Marys Avenue, Hamilton, New York, USA 13346',
    email: 'support@' + domain,
    phone: '+1(315)-824-6202',

}
export default Config
