import {Link} from "react-router-dom";

export default function GamePolicy() {
    document.title = 'Game Policy';
    return (
        <main id="static-page">
            <div className='container'>
                <h1 className="page-title">Game Policy</h1>
                <section className="section page-section">
                    <div className='mb-5'>
                        <p>We prides itself on its transparency with its users, its partners and the market in which it operates.  In furtherance of this principle, this game Policy is aimed at ensuring that developers, users, advertisers and the general public are aware of the guidelines applying to game and applications, and their behaviour, available on our sites. There are further specific guidelines applying to specific language sites or services of us.</p>
                        <h4 className='text-primary'>A) BASIC PRINCIPLES</h4>
                        <ol>
                            <li>The game or application should propose and deliver a clear valuable proposition to users.</li>
                            <li>We reserve the right to not make available from its websites, game or applications that we, in its discretion, considers to be or related to malware, spyware or viruses, nor potential threats to a user´s privacy.</li>
                            <li>The unintended download of game or applications without user consent (“drive-by downloads”) will not be allowed.</li>
                        </ol>
                    </div>


                    <div className='mb-5'>
                        <h4 className='text-primary'>B) DISCLOSURE AND CLEAR BEHAVIOUR</h4>
                        <ol>
                            <li>At the time of installation, users should be able to clearly understand the principal and significant functions of the game and the specific effects the game will have on their computers.</li>
                            <li>The game or application should have a verified signature obtained from a trusted certificate authority.</li>
                            <li>The landing page relating to the game or application must clearly identify the owner of the game or application, with a valid physical mailing address and email that’s clearly visible.</li>
                            <li>Prior to installation of the game or application, the user should have the opportunity to review and accept the relevant Terms of Service (TOS) or End User License Agreement (EULA) and privacy policy.</li>
                            <li>The game or application should not be installed silently on the user’s device.</li>
                            <li>Users should be given a meaningful opportunity to review all proposed installation options and system changes when installing game, and they must not be misled or deceived when approving such changes.</li>
                            <li>Users should be given a “skip all” functionality on the first offer page for all ads or offers included in bundled game.</li>
                            <li>The name of each bundled application should be clearly visible to the user and be clearly disclosed prior to download.</li>
                            <li>Neither the primary game nor any bundled application may perform ad injection.</li>
                            <li>Game or applications should not engage in deceptive behavior (for example, misleading advertising or scare tactics).</li>
                            <li>Game and applications should not engage in unexpected behavior, included but not limited to:
                                <ul>
                                    <li>Negatively impact system performance beyond reasonable user expectations</li>
                                    <li>Hinder or hijack default usability of the system</li>
                                    <li>Circumventing technical protection measures of other applications or content</li>
                                    <li>Sniffing or altering network traffic, unless it is the disclosed core functionality of the game</li>
                                    <li>Take measures to evade anti malware testing</li>
                                </ul>
                            </li>
                            <li>Game and application updates should be conspicuously notified to the user. If the update affects a main functionality, the user will have to approve it.</li>
                            <li>Game and applications must follow current browser modification policies.</li>
                            <li>Game and applications that seek to collect or transmit personally identifiable information, must do so in a transparent way by clearly stating the specific information that will be collected or transmitted.</li>
                            <li>Financial and other sensitive information should not be processed without encryption.</li>
                            <li>Game and applications must not send spam.</li>
                        </ol>
                    </div>


                    <div className='mb-5'>
                        <h4 className='text-primary'>C) CLEAR UNINSTALLATION PROCESS</h4>
                        <ol>
                            <li>It should be easy for users to disable or delete any game or application from the user´s system or device.</li>
                            <li>Users must not be charged for game or application removal, and they must not be required to go through a lengthy process of unrelated uninstall tasks.</li>
                            <li>Users should not be presented new offers during the uninstall process or after finishing it.</li>
                            <li>If additional options are presented to the user, the option to completely remove the game or application should be the most prominent one.</li>
                            <li>Uninstallation cannot impact unrelated files or otherwise utilize tricks or scare tactics to deter uninstallation. An example of such a tactic is a misleading claim about negative effects to the user’s computer or privacy.</li>
                            <li>Game and applications that affect or change a computer’s settings should be reverted. In case of browser’s modifications it must clearly indicate how the user can undo those changes.</li>
                            <li>Once the game or application is disabled or deleted, it may not leave any remnants or programs, and may not remain active or be automatically enabled later by itself or another game or application.</li>
                        </ol>
                        <p>We reserve the right to immediately reject any game or applications, or blacklist game or applications from appearing on its websites, in breach of this policy, or considered harmful to its users, its image or its interests, in its sole discretion. This policy is constantly being refined. We count on your help for detecting breaches of it and improving it, so please do not hesitate to <Link to='/contact-us'>contact us</Link>.</p>
                    </div>
                </section>
            </div>
        </main>
    )
}