import {Link, NavLink, useSearchParams} from 'react-router-dom';
import {HashLink, NavHashLink} from 'react-router-hash-link';
import {useEffect, useState} from "react";
import Config from "../config";
import Classname from "../library/Classname";

export default function Navbar() {
    const [categories, setCategories] = useState([]);
    const [searchParams] = useSearchParams();
    const [mobileMenuClick, setMobileMenuClick] = useState(false);

    useEffect(() => {
        fetch(Config.apiDomain + "category/getlist")
            .then((res) => res.json())
            .then((json) => {
                setCategories(json.data);
            });
    }, []);

    let className =  Classname();

    return (
        <nav id="navbar" className={`${className.navbar["1"]} navbar sticky-top navbar-expand-lg`}>
            <div className="container">
                <NavLink className="navbar-brand p-0" to="/">
                    <img src="/assets/images/logo.png" alt="Logo" />
                </NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink className="nav-link" aria-current="page" to="/">Home</NavLink>
                        </li>
                        <li className="nav-item dropdown">
                            <span className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Category
                            </span>
                            <ul className="dropdown-menu">
                                {categories.map((category, key) => (
                                    <NavLink to={category.link} key={key} className="nav-link">{category.title}</NavLink>
                                ))}
                            </ul>
                        </li>
                        <li className="nav-item">
                            <HashLink to="/#features" smooth className={"nav-link"}>Features</HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink to="/#faq" smooth className={"nav-link"}>FAQ</HashLink>
                        </li>
                    </ul>
                    <form className="d-flex" role="search">
                        <span
                            className="flex-shrink-0 header-search-icon"
                            data-bs-toggle="modal"
                            data-bs-target="#searchModal"
                        >
                            <i className="bi bi-search"></i>
                        </span>
                    </form>
                </div>
            </div>
        </nav>
    );
}
