import Config from "../../config";

export default function ContactUs() {
    document.title = 'Contact Us';
    return (
        <main id="static-page">
            <div className='container'>
                <h1 className="page-title">Contact Us</h1>
                <section className="section page-section contact-us-page">
                    <div className='mb-5'>
                        <p>You can contact us via:</p>
                        <p><strong>Address:</strong> {Config.address}</p>
                        <p><strong>Email:</strong> {Config.email}</p>
                        <p><strong>Phone:</strong> {Config.phone}</p>
                    </div>
                    <div className='text-center'>
                        <img className='contact-image' src="/assets/images/contact-us.jpg" alt="Contact Us" />
                    </div>
                </section>
            </div>
        </main>
    )
}